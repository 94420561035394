import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { endpoint } from '../../../../../src/environments/environment';
import * as jwt_decode from 'jwt-decode';
import { RequestModel } from '../models/RequestModel';
import { Observable } from 'rxjs';
import { CorsHeaders } from '../../../../app/core/services/corsHeader';
import { requestmodelPaginated } from '../models/RequetModelPaginated';
import { BillVoucher } from '../models/BillVoucher';

@Injectable({
  providedIn: 'root'
})
export class RequestLogService {

  headers = new HttpHeaders();
  constructor(private http: HttpClient,private corsHeader:CorsHeaders) { }

  getRequestServiceList(requestid: number, from: string, to: string, status: number, customer: string, reference: string, pageSize : number,page:number): Observable<requestmodelPaginated> {
    return this.http.get<requestmodelPaginated>(`${endpoint.requestService}/?requestId=${requestid}&from=${from}&to=${to} 23:59:59&statusId=${status}&customerId=${customer}&reference=${reference}&pageSize=${pageSize}&page=${page}`, { headers: this.corsHeader.headers()});
  }
  getBillVoucher( billVoucher : string,from: string, to: string, status: number, customer: string, reference: string): Observable<BillVoucher[]> {
    return this.http.get<BillVoucher[]>(`${endpoint.billVoucher}/?from=${from}&to=${to} 23:59:59&status=${status}&billId=${billVoucher}&userId=${customer}&reference=${reference}`, { headers: this.corsHeader.headers()});
  }
  getRequestLog(requestId : string): Observable<any[]> { 
    return this.http.get<any[]>(`${endpoint.requestService}/Getrequest_log/?id=${requestId}&log`, { headers: this.corsHeader.headers() });
  }

  getCompany(): Observable<any[]> { 
    return this.http.get<any[]>(`${endpoint.company}`, { headers: this.corsHeader.headers() });
  }
  getCompanyByName(name:any): Observable<any[]> { 
    return this.http.get<any[]>(`${endpoint.company}/getByName?name=${name}`, { headers: this.corsHeader.headers() });
  }
}
